import $ from 'jquery';
import disableScroll from 'disable-scroll';

const hamburgerBtn = $('#hamburger'),
    mobileMenu = $('#mobileMenu'),
    toggleMenuTime = 600;

// Click on hamburger button
$(hamburgerBtn).click( e => {
    e.stopPropagation();

    $(hamburgerBtn).toggleClass('hamburger--active');

    if ( $(hamburgerBtn).hasClass('hamburger--active') ) {
        disableScroll.on();
        $(mobileMenu).slideDown(toggleMenuTime);

        $('.home-screen__about-items').add('.home-screen__btn-wr').css('opacity', 0);
    } else {
        disableScroll.off();
        $(mobileMenu).slideUp(toggleMenuTime);

        $('.home-screen__about-items').add('.home-screen__btn-wr').css('opacity', 1);
    }
});
// End click on hamburger button

// Open/close dropdown
$('.mobile-nav__link--dropdown').click( e => {
    const clickedEl = $(e.target).closest('.mobile-nav__link--dropdown');

    if ( $(clickedEl).hasClass('mobile-nav__link--dropdown-active') ) {
        $(clickedEl).removeClass('mobile-nav__link--dropdown-active');

        $('.mobile-nav__dropdown').slideUp(toggleMenuTime);
        $('.home-screen__about-items').add('.home-screen__btn-wr').css('opacity', 1);
    } else {
        $(clickedEl).addClass('mobile-nav__link--dropdown-active');

        // Close all opened dropdowns
        $('.mobile-nav__dropdown').slideUp(toggleMenuTime);
        $('.home-screen__about-items').add('.home-screen__btn-wr').css('opacity', 1);

        // Open dropdown for clicked element
        $(clickedEl).next('.mobile-nav__dropdown').slideDown();
    }
});
// End open/close dropdown

// When click outside mobile menu
$(document).click( () => {
    $(mobileMenu).slideUp(toggleMenuTime);
    $(hamburgerBtn).removeClass('hamburger--active');
    $('.home-screen__about-items').add('.home-screen__btn-wr').css('opacity', 1);
    disableScroll.off();
})
// End when click outside mobile menu

// Stop closing menu when click on mobile menu
$(mobileMenu).click( e => e.stopPropagation());
// End stop closing menu when click on mobile menu

window.onload = () => {
    $('#preloader').fadeOut();
}