import '@styles/home.scss';

import fullpage from 'fullpage.js';
import * as ScrollMagic from "scrollmagic";
import { TweenMax, TimelineMax } from "gsap";
import { ScrollMagicPluginGsap } from "scrollmagic-plugin-gsap";
import $ from 'jquery';
import SmoothScroll from 'smooth-scroll/src/js/smooth-scroll/smooth-scroll';

ScrollMagicPluginGsap(ScrollMagic, TweenMax, TimelineMax);

import './modules/mobile-menu';

new SmoothScroll('a[href*="#"]');

if ( window.matchMedia("(min-width: 991px)").matches ) {
    $('section').removeAttr('id');

    const homeHome = new TimelineMax();
    const homeSoftware = new TimelineMax();
    const homeSecurity = new TimelineMax();
    const homeProducts = new TimelineMax();

    homeHome.fromTo('.home-screen__bg', 1, {opacity:0}, {opacity:1})
        .fromTo('.home-screen__title', 1, {y:50, opacity:0}, {y:0, opacity:1}, '=-1')
        .staggerFromTo('.home-screen__about-icon', .5, {y:20, opacity:0}, {y:0, opacity:1}, .5, '=-1')
        .staggerFromTo('.home-screen__about-title', .5, {y:20, opacity:0}, {y:0, opacity:1}, .5, '=-1.5')
        .fromTo('.home-screen__btn', .5, {y:20, opacity:0}, {y:0, opacity:1});

    homeSoftware.from('.home-software__img', 1, {opacity: 0, x: -100})
        .from('.home-software__layout', 1, {opacity: 0, x: 50}, '=-1')
        .staggerFrom('.home-software__layout-item', .5, {opacity: 0, y: 20}, .5, '=-.5');

    homeSecurity.from('.home-security__img', 1, {opacity: 0, x: 100})
        .from('.home-security__layout', 1, {opacity: 0, x: 50}, '=-1')
        .staggerFrom('.home-security__layout-item', .5, {opacity: 0, y: 20}, .5, '=-.5');

    homeProducts.from('.home-products__title', 1, {opacity: 0, y: 20}, .5)
        .from('.home-product', .4, {opacity: 0, x: 20}, '=-.5');
        // .staggerFrom('.home-product', .4, {opacity: 0, x: 20}, .5, '=-.5');

    // document.addEventListener("DOMContentLoaded", () => homeHome.restart());

    // window.onload = () => {
    //     homeHome.restart()
    // }

    new fullpage('#fullpage', {
        anchors: ['home-screen', 'web-mobile-software-development', 'cyber-security', 'our-products', 'contacts'],
        licenseKey: '643A8C2B-18D047C0-845E464B-28BFE23E',
        responsiveWidth: 992,
        navigation: true,
        navigationPosition: 'right',
        navigationTooltips: ['Home screen', 'Software development', 'Cyber Security', 'Our products', 'Contacts'],

        onLeave: (origin, destination) => {
            // if (destination.index == 0) homeHome.restart();
            if (destination.index == 1) homeSoftware.restart();
            if (destination.index == 2) homeSecurity.restart();
            if (destination.index == 3) homeProducts.restart();

            if (destination.index == 4) $('#fp-nav').addClass('dark');
            else $('#fp-nav').removeClass('dark');
        }
    });
} else {

    const homeHome = new TimelineMax();
    const homeSoftware = new TimelineMax({onUpdate:updatePercentage});
    const homeSecurity = new TimelineMax({onUpdate:updatePercentage});
    const homeProducts = new TimelineMax({onUpdate:updatePercentage});

    homeHome.fromTo('.home-screen__bg', 1, {opacity:0}, {opacity:1})
        .fromTo('.home-screen__title', 1, {y:50, opacity:0}, {y:0, opacity:1}, '=-1')
        .staggerFromTo('.home-screen__about-icon', .5, {y:20, opacity:0}, {y:0, opacity:1}, .5, '=-1')
        .staggerFromTo('.home-screen__about-title', .5, {y:20, opacity:0}, {y:0, opacity:1}, .5, '=-1.5')
        .fromTo('.home-screen__btn', .5, {y:20, opacity:0}, {y:0, opacity:1});

    homeSoftware.from('.home-software__img', 1, {opacity: 0, x: -100})
        .from('.home-software__layout', 1, {opacity: 0, x: 50}, '=-1')
        .staggerFrom('.home-software__layout-item', .5, {opacity: 0, y: 20}, .5, '=-.5');

    homeSecurity.from('.home-security__img', 1, {opacity: 0, x: 100})
        .from('.home-security__layout', 1, {opacity: 0, x: 50}, '=-1')
        .staggerFrom('.home-security__layout-item', .5, {opacity: 0, y: 20}, .5, '=-.5');

    homeProducts.from('.home-products__title', 1, {opacity: 0, y: 20}, .5)
        .from('.home-product', .4, {opacity: 0, x: 20}, '=-.5');
    // .staggerFrom('.home-product', .4, {opacity: 0, x: 20}, .5, '=-.5');

    const controller = new ScrollMagic.Controller();

    const homeSoftwareScene = new ScrollMagic.Scene({
        triggerElement: "#homeSoftware",
        offset: 0,
        reverse: false
    })
        .setTween(homeSoftware)
        .addTo(controller)

    const homeProductsScene = new ScrollMagic.Scene({
        triggerElement: "#homeProducts",
        offset: 0,
        reverse: false
    })
        .setTween(homeProducts)
        .addTo(controller)

    const homeSecurityScene = new ScrollMagic.Scene({
        triggerElement: "#homeSecurity",
        offset: 0,
        reverse: false
    })
        .setTween(homeSecurity)
        .addTo(controller)

    function updatePercentage() {
        homeSoftware.progress();
        homeSecurity.progress();
        homeProducts.progress();
    }
}